form {
	display: flex;
	flex-flow: column;
	width: 90%;
}

.form-group {
	display: flex;
	flex-flow: row wrap;
	margin: 10px;
	align-items: center;
}

.form-group label {
	font-size: 1.2rem;
	font-weight: 600;
	color: #eee;
}

.form-group .spacer {
	flex-grow: 1;
}

.form-group input, .form-group textarea {
	padding: 10px;
	border-radius: 5px;
	background-color: transparent;
	outline: none;
	margin: 10px;
	width: 250px;
	border: 1px solid #4d90fa;
	font-size: 1.2rem;
}

.form-group textarea {
	display: block;
	margin-left: 0;
	/* Give it a default size */
	width: 100%;
	height: 200px;
}

form .button {
	width: 100px;
	padding: 10px;
	background-color: transparent;
	outline: none;
	margin: 10px;
	font-size: 1.2rem;
	font-weight: 600;
	color: #4d90fa;
	cursor: pointer;
	display: flex;
	justify-content: center;
	text-decoration: none !important;
}

form .button.primary {
	border-radius: 5px;
	border: 1px solid #4d90fa;
}