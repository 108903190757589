* {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
	color: #ddd;
}
  
body {
	background: rgb(8, 10, 14);
	background: linear-gradient(132deg, rgb(8, 10, 14) 0%, rgb(37, 53, 77) 100%);
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh !important;
}

.container {
	display: flex;
	flex-flow: column;
	width: 100%;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 20vh;
}
.container .innerContainer {
	padding: 50px 0;
	width: 60vw;
}

.container p {
	font-size: 1.4rem;
	width: 50vw;
	margin-top: 15px;
	margin-bottom: 15px;
}

.container .mainUl {
	list-style-position: inside;
	font-size: 1.4rem;
	width: 50vw;
	padding: 15px;
}
.container .mainUl li {
	padding: 10px 0;
}
.container .mainUl li ul {
	list-style-position: inside;
	padding: 0 3rem;
	margin: 1.5rem 0 0 0;
}

.container a {
	color: #4d90fa !important;
	-webkit-text-decoration: underline rgba(77, 144, 250, 0);
			text-decoration: underline rgba(77, 144, 250, 0);
	transition: text-decoration-color 0.2s ease-in-out;
}
.container a:hover {
	text-decoration-color: #4d90fa;
}

.container .skillContainer {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	gap: 30px;
	width: 45vw;
	font-size: 1.4rem;
}

@media screen and (max-width: 800px) {
	.innerContainer, ul, p, .skillContainer {
		width: 90vw !important;
	}
}

.container .experience {
	margin: 3rem 0;
}
.container .highlight {
	font-weight: 900;
}
.container h1 {
	font-size: 3.4rem;
	margin-bottom: 3vh;
	margin-top: 5vh;
}
.container h3 {
	font-size: 2rem;
}
.container h6 {
	font-size: 1.1rem;
	color: #354d73;
}
.container h6 .experienceTime {
	color: #4873b5;
}

.bigLoaderContainer {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.bigLoader {
	border: 2rem solid #f3f3f3;
	border-top: 2rem solid #3498db;
	border-radius: 50%;
	width: 20vw;
	height: 20vw;
	display: inline-block;
	opacity: 1;
}

.bigLoader.loading {
	opacity: 1;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}