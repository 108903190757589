.menuContainer {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-evenly;
	margin-top: 3vh;
	gap: 5px;
	margin-bottom: 3vh;
	margin-right: 34vw;
}
.menuContainer .menuInner {
	display: flex;
	flex-flow: row wrap;
	min-width: 20%;
	margin: 0 10vw;
	justify-content: space-evenly;
}
.menuContainer .menuInner .menuLink {
	padding: 0.5vh 0.7vw;
	margin: 0 1vw;
	-webkit-text-decoration: underline rgba(77, 144, 250, 0);
			text-decoration: underline rgba(77, 144, 250, 0);
	font-size: 1.2rem;
	color: #4d90fa;
	border-radius: 5px;
	transition: background-color 0.2s ease-in-out, text-decoration-color 0.2s ease-in-out;
}
.menuContainer .menuInner .menuLink:hover {
	background-color: rgba(77, 144, 250, 0.1);
	text-decoration-color: #4d90fa;
}

@media screen and (max-width: 1100px) {
	.menuContainer {
		min-width: 100%;
	}
}