.mainContent {
	/* Slide it 320 px to the right and make the width 100VW - 320PX */
	margin-left: 320px;
	width: calc(100vw - 320px);
	margin-top: 20px;
}

input, textarea {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
	background-color: transparent;
	outline: none;
	margin: 10px;
}

textarea {
	display: block;
	margin-left: 0;
}

.mainContent form {
	display: flex;
	flex-flow: column;
	width: 100%;
}

.mainContent form div {
	margin: 10px;
}

.mainContent form .submitButton {
	width: 100px;
}

.mainContent table {
	width: 90%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

.mainContent table thead tr th {
	padding: 10px 0;
	font-weight: 400;
	color: #4d90fa;
	text-align: left;
}

.mainContent table tbody tr:nth-child(odd) {
	background-color: rgba(44, 61, 88, 0.289);
}

.mainContent table tbody tr td, .mainContent table thead tr th {
	padding: 10px 20px;
	text-align: left;
}

.spanButton {
	cursor: pointer;
	padding: 10px;
	padding-right: 20px;
	padding-left: 0;
	color: #4d90fa;
}
