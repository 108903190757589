body {
	min-width: 100vw;
	min-height: 100vh;
}

.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 300px;
	z-index: 100;
	transition: all 0.3s ease-in-out;
	display: flex;
	flex-flow: column;
	background-color: rgba(0, 0, 0, 0.3);
}

.sidebar .sidebarItem {
	width: 80%;
	margin: 10px 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.sidebar .sidebarItem .sidebarMainTitle {
	font-size: 1.5rem;
	font-weight: 600;
	color: white;
	margin-left: 10px;
	margin-top: 10px;
}

.sidebar .sidebarItem .sidebarSubtitle {
	font-size: 1.3rem;
	font-weight: 600;
	color: white;
	margin-left: 10px;
	margin-bottom: 30px;
}

.sidebar .sidebarItem .sidebarItemText {
	font-size: 1rem;
	font-weight: 600;
	color: #4d90fa;
	margin-left: 20px;
	text-decoration: none !important;
}

.sidebar .sidebarItem ul {
	margin: 0;
	padding: 0;
}

.sidebar .sidebarItem ul li {
	list-style: none;
	padding: 10px 0;
	padding-left: 40px;
}

.sidebar .spacer {
	flex-grow: 1;
}

.sidebar .sidebarItem .sidebarItemText.signOutButton {
	margin-bottom: 50px;
	color: #ea5a5a;
	cursor: pointer;
}

.loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 0.8rem;
	height: 0.8rem;
	display: inline-block;
	opacity: 0;
}

.loader.loading {
	opacity: 1;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}