.projectFlex {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-bottom: 15px;
}

.projectFlex .flexChild {
	font-size: 1rem;
	padding: 0.5vh 0.7vw;
	transition: background-color 0.2s ease-in-out;
	border-radius: 5px;
	color: #4d90fa;
}

.projectFlex .flexChild:hover {
	background-color: rgba(77, 144, 250, 0.1);
}

.categoryTitle {
	font-size: 1.1rem;
	font-weight: 600;
	margin-top: 1rem;
	padding: 0.5vh 0.7vw;
}

/* project should be a card with a milk glass like background */
/* The width should be as wide so the content fits, not wider */
.project {
	padding: 1vh 1vw;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.1);
	margin-top: 4rem;
	border-radius: 5px;
}